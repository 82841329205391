/** @jsx jsx */
import React from "react"
import { Link, graphql } from "gatsby"
import { Styled, jsx } from "theme-ui"
import Layout from "../components/Layout"

const DateBox = ({ date }) => (
  <div
    sx={{
      textAlign: "center",
      fontFamily: "heading",
      fontWeight: "heading",
      mt: 1,
    }}
  >
    <div sx={{ fontSize: 5 }}>{new Date(date).getDate()}</div>
    <div>{new Date(date).toLocaleDateString("en-GB", { month: "long" })}</div>
    <div>{new Date(date).getFullYear()}</div>
  </div>
)

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <Layout>
      <div
        sx={{
          py: 2,
        }}
      >
        <div sx={{ variant: "styles.shadowBox", mb: 5 }}>
          <Styled.h1>Blog</Styled.h1>
        </div>

        <ul
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        >
          {posts.map(({ node: post }) => (
            <li key={post.id} sx={{ display: "flex" }}>
              <div sx={{ variant: "styles.shadowBox", width: "5em", height: "6em", mr: 5 }}>
                <DateBox date={post.frontmatter.date} />
              </div>
              <div sx={{ variant: "styles.shadowBox", flexGrow: "1" }}>
                <Styled.h2 sx={{ mb: 4 }}>
                  <Link
                    to={post.frontmatter.path}
                    sx={{
                      color: "inherit",
                      textDecoration: "none",
                      ":hover,:focus": {
                        color: "primary",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {post.frontmatter.title}
                  </Link>
                </Styled.h2>
                <Styled.p>{post.excerpt}</Styled.p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx(
      filter: { fileAbsolutePath: { regex: "//src/blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            path
            date
          }
        }
      }
    }
  }
`
export default BlogIndex
